import React from 'react'
import { useAuthentication, useEpiContent } from '@trr/app-shell-data'
import { adminRoles } from 'utils'
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
} from '@mui/material'
import { Content } from 'types'
import { LinkList, NoneFound } from './components'
import { useGetArbetsgivarInfoLinksQuery } from 'Api'

const isWithinExpirationDate = (endDate: string | null) => {
  if (!endDate) {
    return true
  }
  const endDateTime = new Date(endDate)
  const today = new Date()
  endDateTime.setHours(0, 0, 0, 0)
  today.setHours(0, 0, 0, 0)

  return endDateTime >= today
}

const Admin = () => {
  const { userRoles } = useAuthentication()
  const content = useEpiContent<Content>()
  const isAdmin = adminRoles.some((adminRole) => userRoles.includes(adminRole))
  const { data, isLoading } = useGetArbetsgivarInfoLinksQuery()
  const activeLinks =
    data
      ?.filter((link) => link.isEnabled && isWithinExpirationDate(link.endTime))
      .slice(0, 3) ?? []
  if (!isAdmin || isLoading) {
    return null
  }
  return (
    <Card data-testid="arbetsgivarinfoAdmin">
      <CardContent>
        <Typography variant="h6" component={'h2'} mb={1}>
          {content.adminHeading}
        </Typography>
        {activeLinks.length === 0 ? (
          <NoneFound />
        ) : (
          <LinkList activeLinks={activeLinks} />
        )}
      </CardContent>
      <CardActions>
        <Button
          data-testid="goToAllLinks"
          href={content.adminLinkUrl}
          size="small"
          variant="text"
        >
          {content.adminLinkText}
        </Button>
      </CardActions>
    </Card>
  )
}

export default Admin
