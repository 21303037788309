import {
  Avatar,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material'
import LinkIcon from '@mui/icons-material/Link'
import React from 'react'
import { useEpiContent } from '@trr/app-shell-data'
import { Content, GetArbetsgivarInfoLinksResponse } from 'types'
import { date } from '@trr/frontend-datetime'

const LinkList = ({
  activeLinks,
}: {
  activeLinks: GetArbetsgivarInfoLinksResponse
}) => {
  const content = useEpiContent<Content>()
  return (
    <List>
      {activeLinks.map((link) => {
        return (
          <ListItem key={link.id} data-testid="link-list-item">
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: 'surface.green' }}>
                <LinkIcon sx={{ color: 'secondary.dark' }} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{
                component: 'h6',
                variant: 'subtitle1',
              }}
              primary={link.name}
              secondaryTypographyProps={{
                component: 'div',
              }}
              secondary={
                link.endTime
                  ? `Aktiv till ${date.format(link.endTime, { style: 'numeric' })}`
                  : 'Aktiv'
              }
            />
            <Link
              data-testid="arbetsgivarInfoEditLink"
              fontSize={14}
              href={`${content.adminEditUrl}${link.id}`}
            >
              {content.adminEditText}
            </Link>
          </ListItem>
        )
      })}
    </List>
  )
}

export default LinkList
