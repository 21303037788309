import React from 'react'
import { useAuthentication, useEpiContent } from '@trr/app-shell-data'
import { adminRoles } from 'utils'
import { Box, Card, CardContent, Divider, Typography } from '@mui/material'
import { Content } from 'types'
import { useGetArbetsgivarInfoLinksQuery } from 'Api'

const Consumer = () => {
  const { userRoles } = useAuthentication()
  const isAdmin = adminRoles.some((adminRole) => userRoles.includes(adminRole))
  const content = useEpiContent<Content>()
  const { data, isLoading } = useGetArbetsgivarInfoLinksQuery()
  const activeLinks = data?.filter((link) => link.isEnabled) ?? []
  if (isAdmin || isLoading || activeLinks.length === 0) {
    return null
  }
  return (
    <Card
      data-testid="arbetsgivarinfoConsumer"
      elevation={0}
      sx={{
        border: '1px solid #ccc',
        backgroundColor: 'surface.green',
      }}
    >
      <CardContent>
        <Typography variant="h6" component="h2" gutterBottom>
          {content.consumerHeading}
        </Typography>
        <Box>
          {activeLinks.map((link, i) => (
            <React.Fragment key={link.id}>
              <Typography
                data-testid="arbetsgivarinfoConsumerText"
                variant="body1"
              >
                {link.text}
              </Typography>
              {activeLinks.length - 1 !== i && (
                <Divider
                  data-testid="arbetsgivarinfoConsumerDivider"
                  sx={{ my: 2 }}
                />
              )}
            </React.Fragment>
          ))}
        </Box>
      </CardContent>
    </Card>
  )
}

export default Consumer
